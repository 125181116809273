import { TWpGlobal } from "@@types/wordPress";
import { imgDefault, SEO_BLOG, SEO_BLOG_BUSINESS } from "@constants";
import useScreenSizeListener from "@hooks/screenSizeListener/useScreenSizeListener";
import parse from "html-react-parser";
import Image from "next/image";
import Link from "next/link";
import React, { Fragment, useEffect, useState } from "react";
import style from "./postRow.module.scss";

type PostRowProps = {
  data: TWpGlobal[];
  quantity?: number;
};

const PostRow: React.FC<PostRowProps> = ({ data, quantity }) => {
  const postFilter = data.slice(0, quantity);
  const isMobile = useScreenSizeListener(600);
  const [numCharacters, setNumCharacters] = useState(210);

  useEffect(() => {
    if (!isMobile) {
      setNumCharacters(120);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {postFilter.map((b, index) => (
        <article key={index} className={style.PostRow}>
          <Link
            href={b.categories.includes(78) ? `/${SEO_BLOG_BUSINESS}/[slug]` : `/${SEO_BLOG}/[slug]`}
            as={b.categories.includes(78) ? `/${SEO_BLOG_BUSINESS}/${b.slug}` : `/${SEO_BLOG}/${b.slug}`}
          >
            <Image
              src={!b.uagb_featured_image_src.large[0] ? imgDefault : (b.uagb_featured_image_src.large[0] as string)}
              width={250}
              height={130}
              alt={b.title.rendered}
            />
            <div>
              <h2> {parse(b.title.rendered)} </h2>
              <div className={style.PostRowInfo}>{parse(b.excerpt.rendered.slice(0, numCharacters) + "...")}</div>
            </div>
          </Link>
        </article>
      ))}
    </Fragment>
  );
};

export default PostRow;
