/* eslint-disable react/prop-types */
import { TWpGlobal } from "@@types/wordPress";
import ButtonDefault from "@components/buttons/btnDefault/component";
import parse from "html-react-parser";
import { TFunction, withTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { Fragment } from "react";
import style from "./bannerPost.module.scss";

type BannerPostProps = {
  data: TWpGlobal[];
  quantity?: number;
  t: TFunction;
};

const BannerPost: React.FC<BannerPostProps> = ({ data, quantity, t }) => {
  const filter = data.slice(0, quantity);
  const { pathname } = useRouter();
  return (
    <Fragment>
      {filter.map((b, index) => (
        <article key={index} className={style.banner}>
          <div className={style.info}>
            <h2>{parse(b.title.rendered)}</h2>
            <div>{parse(b.excerpt.rendered.slice(0, 120) + "...")}</div>
            <ButtonDefault
              href={`${pathname}/[slug]`}
              as={`${pathname}/${b.slug}`}
              txt={t<string>("buttonTexts.learnMore")}
              icon
              i="las la-angle-right"
              right
            />
          </div>
          <Image
            src={b.uagb_featured_image_src.full[0] as string}
            width={1400}
            height={400}
            alt={b.title.rendered}
            priority
          />
        </article>
      ))}
    </Fragment>
  );
};

export default withTranslation("genericTexts")(BannerPost);
